<template>
  <div>
    <el-card >
      <template #header>
        <span style="font-size: small">{{$t('msg.dray.chassisRecord')}}</span>
        <el-button type="primary" size="small" @click="addRecord" style="float: right">{{ $t('msg.basicInfo.addRecord') }}</el-button>
      </template>
      <el-table :data="chassisRent" style="font-size: smaller">
        <el-table-column prop="invoiceId" :label="$t('msg.account.statementId')"></el-table-column>
        <el-table-column prop="chassisId" :label="$t('msg.dray.chassisId')"></el-table-column>
        <el-table-column prop="ctnrId" :label="$t('msg.track.ctnrId')"></el-table-column>
        <el-table-column prop="pickupTime" :label="$t('msg.track.pickupAva')"></el-table-column>
        <el-table-column prop="returnTime" :label="$t('msg.track.emptyReturnTime')"></el-table-column>
      </el-table>
    </el-card>
    <el-dialog title="Quote Basic Information" v-model="chassisFormShow" v-on:close="resetForm()" width="30%" center draggable>
      <el-form :model="chassisForm" >
        <el-form-item :label="$t('msg.dray.chassisId')" prop="chassisId" style="width: 350px">
          <el-input v-model="chassisForm.chassisId"></el-input>
        </el-form-item>
        <el-form-item :label="$t('msg.track.ctnrId')" prop="ctnrId" required style="width: 420px">
          <el-input v-model="chassisForm.ctnrId"></el-input>
        </el-form-item>
        <el-form-item :label="$t('msg.dray.pickupTime')" prop="pickupTime" required >
          <el-date-picker v-model="chassisForm.pickupTime" type="datetime" placeholder="Pickup Time" format="YYYY/MM/DD HH:mm:ss" value-format="MM/DD/YYYY HH:mm:ss"></el-date-picker>
        </el-form-item>
        <el-form-item :label="$t('msg.track.emptyReturnTime')" prop="returnTime" required >
          <el-date-picker v-model="chassisForm.returnTime" type="datetime" placeholder="Empty Return Time" format="YYYY/MM/DD HH:mm:ss" value-format="MM/DD/YYYY HH:mm:ss"></el-date-picker>
        </el-form-item>
      </el-form>
      <el-button type="primary" v-on:click="recordSubmit()" style="float: right">Submit</el-button>
    </el-dialog>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref } from 'vue'
import axios from 'axios'

export default defineComponent({
  name: 'chassisRent',
  setup() {
    const chassisRent = ref()
    const chassisFormShow = ref(false)
    const chassisForm = ref({ invoiceId: 'NA', chassisId: '', ctnrId: '', pickupTime: '', returnTime: '', result: 'NA' })
    const addRecord = () => {
      chassisFormShow.value = true
    }
    const recordSubmit = () => {
      const param = 'chassisRent=' + JSON.stringify(chassisForm.value)
      const reqURL = process.env.VUE_APP_BASE + '/dray/addChassisRecord'
      axios.post(reqURL, param).then((response) => {
        chassisRent.value = response.data
        chassisFormShow.value = false
        resetForm()
      }).catch((response) => {
        console.log(response)
      })
    }
    onMounted(() => {
      axios.get(process.env.VUE_APP_BASE + '/dray/getChassisRecord').then((response) => {
        chassisRent.value = response.data
      })
    })
    const resetForm = () => {
      chassisForm.value = { invoiceId: '', chassisId: '', ctnrId: '', pickupTime: '', returnTime: '', result: '' }
    }
    return { chassisRent, addRecord, chassisFormShow, resetForm, chassisForm, recordSubmit }
  }
})
</script>
